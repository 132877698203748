<template>
  <div class="outContainer">
    <div class="search">
      <el-select
        class='each'
        v-model="searchInfo.param" 
        clearable
        placeholder="系统类型">
        <el-option
          v-for="item in systemTypeEnumOptions"
          :key="item.code"
          :label="item.desc"
          :value="item.code">
        </el-option>
      </el-select>
      <el-button type="primary" size="mini" @click="handleSearch('search')">搜索</el-button>
      <el-button type="primary" size="mini" @click="handleSearch('clear')">清空搜索条件</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        height="100%"
        style="width: 100%">
        <el-table-column
          prop="name"
          align="center"
          label="模板名称">
        </el-table-column>
        <el-table-column
          prop="systemType"
          align="center"
          label="所属系统">
        </el-table-column>
        <el-table-column
          prop="deviceName"
          align="center"
          label="涉及设备模板">
          <template slot-scope="scope">
            <p>{{ scope.row.deviceTemplate ? scope.row.deviceTemplate.deviceName : '--' }}</p>   
          </template>
        </el-table-column>
        <el-table-column
          prop="autoHoming"
          align="center"
          label="是否自动归位">
          <template slot-scope="scope">
            <p>{{ scope.row.autoHoming ? scope.row.autoHoming : '--' }}</p>   
          </template>
        </el-table-column>
        <el-table-column
          prop="timeConsuming"
          align="center"
          label="耗时（秒）">
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          label="描述">
          <template slot-scope="scope">
            <p>{{ scope.row.remark ? scope.row.remark : '--' }}</p>   
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getActionTemplate,getsystemTypeEnum } from '@/api/public.js';
export default {
  data() {
    return {
      searchInfo:{
        param:null
      },
      tableData:[],
      systemTypeEnumOptions:[], // 系统选项
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.getSystemList();
    this.init();
  },

  methods: {
    // 搜索
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = null;
      }
      this.init();
    },
    // 获取系统列表
    async getSystemList(){
      await getsystemTypeEnum({}).then(res=>{
        if(res.success){
          this.systemTypeEnumOptions = res.data;
        }else{
          this.systemTypeEnumOptions = [];
        }
      })
    },
    // 获取动作模板列表
    async init(){
      await getActionTemplate(this.searchInfo).then(res=>{
        if(res.success){
          this.tableData = res.data;
        }else{
          this.tableData = [];
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.outContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  .table{
    flex: 1;
  }
}
.btn{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
</style>
